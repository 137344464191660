<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Abril 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section>
    <div class="d-flex mt-3">
      <v-text-field v-model="searchClient" class="me-3 ms-3" style="width: 50%" label="Buscar por cliente" dense
        outlined></v-text-field>

      <v-tooltip left color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="success" class="me-3" @mousedown.prevent="modalAgregarRelacion()" fab v-bind="attrs"
            v-on="on">
            <v-icon>add</v-icon>
          </v-btn>
        </template>
        <span>Agregar relación</span>
      </v-tooltip>
    </div>

    <!-- modal para agregar una relación -->
    <v-dialog v-model="dialogAgregarRelacion" persistent max-width="700px">
      <v-card>
        <v-card-title class="fondoHeder">
          {{ editRelationship ? 'Editar relación cliente - bodega' : 'Agregar relación cliente - bodega' }}
        </v-card-title>
        <div class="d-flex justify-center mt-3 ms-3">
          <v-autocomplete v-model="addRelationship.client" :readonly="editRelationship"
            :items="addRelationship.clientItems" class="me-2" @keyup="delayGetClients($event.target.value)"
            @change="getDetailClient()" label="Cliente" dense outlined></v-autocomplete>
          <v-autocomplete v-model="addRelationship.detailClient" :readonly="editRelationship"
            :items="addRelationship.detailClientItems" class="me-2" label="Detalle cliente" dense
            outlined></v-autocomplete>
          <v-autocomplete v-model="addRelationship.winery" :items="addRelationship.wineryItems" class="me-2"
            @keyup="delayGetWinery($event.target.value)" label="Bodega" dense outlined></v-autocomplete>
        </div>
        <section class="d-flex justify-end me-3 pb-3">
          <v-btn color="error" text @click="modalCloseRelationship()"> Cerrar </v-btn>
          <v-btn color="success" @click="saveRelationship()"> guardar </v-btn>
        </section>
      </v-card>
    </v-dialog>

    <!-- tabla donde muestra busqueda de realaciones -->
    <v-data-table class="tablaRelaciones mx-3" height="59vh" :loading="cargandoDatos"
      :server-items-length="totalElementos" :headers="tableHeaders" :page="pagina" :items-per-page="itemsPorPagina"
      :items="listarRelaciones" @update:items-per-page="(i) => itemsPorPagina = i" @update:page="(p) => pagina = p"
      :footer-props="footerProps" fixed-header>

      <!-- agrega chip de estado a cada una de las relaciones -->
      <template v-slot:[`item.ACT_ESTA`]="{ item }">
        <div class="d-flex align-center">
          <v-icon :color="item.ACT_ESTA === 'A' ? 'green darken-2' : 'red'">
            {{ item.ACT_ESTA === 'A' ? 'check_circle' : 'cancel' }}
          </v-icon>
          {{ item.ACT_ESTA === 'A' ? 'Activo' : 'Inactivo' }}
        </div>
      </template>

      <!-- agrega boton para cambiar estado a cada una de las relaciones -->
      <template v-slot:[`item.acciones`]="{ item }">
        <section class="d-flex">
          <v-tooltip bottom :color="item.ACT_ESTA === 'A' ? 'error' : 'success'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon :color="item.ACT_ESTA === 'A' ? 'error' : 'success'" @click="modalActivoDesactivo(item)"
                v-bind="attrs" v-on="on">
                <v-icon>{{ item.ACT_ESTA === 'A' ? 'disabled_by_default' : 'fact_check' }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.ACT_ESTA === 'A' ? 'Inactivar' : 'Activar' }}</span>
          </v-tooltip>

          <!-- agrega boton editar las relaciones -->
          <v-tooltip bottom color="warning">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon color="warning" @click="editRelation(item)" v-bind="attrs" v-on="on">
                <v-icon>border_color</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </section>
      </template>


      <!-- Botón para ver la trazabilidad -->
      <!-- <template v-slot:[`item.trazabilidad`]="{ item }">
        <v-tooltip bottom color="blue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon color="blue" @click="listarTrazabilidad(item)" v-bind="attrs" v-on="on">
              <v-icon>backup_table</v-icon></v-btn>
          </template>
          <span>Trazabilidad</span>
        </v-tooltip>
      </template> -->
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- modal para confimar activar o desactivar relacion -->
    <v-dialog v-model="dialogEstado" max-width="360">
      <v-card>
        <v-card-title class="fondoHeder">
          {{
            relacionSeleccionada.ACT_ESTA === 'A' ? '¿Desea desactivar la relación?' : '¿Desea activar la relación?'
          }}
        </v-card-title>
        <div class="d-flex justify-end pa-3">
          <v-btn color="error" text @click="dialogEstado = false"> No </v-btn>
          <v-btn color="success" @click="actualizarEstadoPostgres(relacionSeleccionada, estadoRelacionActualizar)">
            Si </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </section>
</template>


<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";

const debounce = function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  name: 'Clientes',
  data: () => ({
    searchClient: '',
    cargandoDatos: false,
    listarRelaciones: [],
    itemsPorPagina: 10,
    itemFilas: [10, 20, 30, 40],
    pagina: 1,
    totalElementos: 0,
    dialogAgregarRelacion: false,
    relacionSeleccionada: {},
    dialogEstado: false,
    estadoRelacionActualizar: null,
    editRelationship: false,
    bodegaActual: '',
    addRelationship: {
      client: '',
      clientItems: [],
      detailClient: '',
      detailClientItems: [],
      winery: '',
      wineryItems: []
    },
    footerProps: {
      "show-first-last-page": true,
      "items-per-page-text": "Ítems por página",
      "items-per-page-options": [10, 20, 30, 40],
      "show-current-page": true,
    },
    tableHeaders: [
      { text: "CLIENTE", value: "nombre", sortable: false },
      { text: "DETALLE CLIENTE", value: "NOM_DECL", sortable: false, },
      { text: "BODEGA", value: "NOM_BODE", sortable: false, },
      { text: "ESTADO", value: "ACT_ESTA", sortable: false, },
      { text: "ACCIONES", value: "acciones", sortable: false, },
      // { text: "TRAZABILIDAD", value: "trazabilidad", align: "center", sortable: false, },
    ],
  }),
  watch: {
    "searchClient": function () { this.getRelacionesHerinco(); },
    "itemsPorPagina": function () { this.getRelacionesHerinco(); },
    "pagina": function () { this.getRelacionesHerinco(); },
  },
  mounted() {
    this.getRelacionesHerinco();
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "trazabilidad", "enterprise"]),
  },
  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),
    delayGetClients: debounce(function (param) {
      this.getClients(param);
    }, 500),

    delayGetWinery: debounce(function (param) {
      this.getWinery(param);
    }, 500),

    getRelacionesHerinco() {
      this.listarRelaciones = [];
      this.cargandoDatos = true;
      this.$http.get(`msa-administration/api/DpBoes/relaciones`, {
        params: {
          cliente: this.searchClient,
          codigoEmpresa: this.enterprise.code,
          pageNumber: this.pagina - 1,
          pageSize: this.itemsPorPagina
        }
      }).then(({ data }) => {
        this.listarRelaciones = data.content;
        this.totalElementos = data.totalElements;
        this.cargandoDatos = false;
      }).catch(err => console.log(err));
    },
    modalActivoDesactivo(item) {
      this.dialogEstado = true;
      this.relacionSeleccionada = item;
      item.ACT_ESTA === "A" ? this.estadoRelacionActualizar = "B" : this.estadoRelacionActualizar = "A";
    },

    modalAgregarRelacion() {
      this.dialogAgregarRelacion = true;
    },
    modalCloseRelationship() {
      this.dialogAgregarRelacion = false;
      this.addRelationship.client = '';
      this.addRelationship.clientItems = [];
      this.addRelationship.detailClientItems = [];
      this.addRelationship.detailClient = '';
      this.addRelationship.winery = '';
      this.addRelationship.wineryItems = [];
      this.editRelationship = false;
    },
    listarTrazabilidad(item) {
      console.log(item);
    },
    getClients(codigo) {
      this.addRelationship.clientItems = [];
      if (codigo.length > 2) {
        this.$http.get(`msa-stone-integration/ad/cliente`, {
          params: {
            codigoEmpresa: this.enterprise.code,
            nombreCliente: codigo,
            limite: 0
          }
        }).then(({ data }) => data.CLIENTES.forEach(client => {
          this.addRelationship.clientItems.push({ text: client.NOM_COMP, value: client.NUM_IDEN })
        })).catch(err => console.log(err))
      }
    },
    getDetailClient() {
      this.addRelationship.detailClientItems = [];
      this.$http.get(`msa-stone-integration/ad/detalle-cliente`, {
        params: {
          codigoEmpresa: this.enterprise.code,
          codigoCliente: this.addRelationship.client,
        }
      }).then(({ data }) => data.DetallesCliente.forEach(detailClient => {
        this.addRelationship.detailClientItems.push({ text: detailClient.NOM_DECL, value: detailClient.COD_DECL })
      })).catch(err => console.log(err))

    },
    getWinery(bodega) {
      this.addRelationship.wineryItems = [];
      this.$http.get(`msa-stone-integration/in/bodega`, {
        params: {
          codigoEmpresa: this.enterprise.code,
          nombreBodega: bodega
        }
      }).then(({ data }) => data.Bodegas.forEach(bodega => {
        this.addRelationship.wineryItems.push({ text: bodega.NOM_BODE, value: bodega.COD_BODE })
      })).catch(err => console.log(err))
    },

    saveRelationship() {
      if (this.addRelationship.client != '' && this.addRelationship.detailClient != '' && this.addRelationship.winery != '') {

        if (this.editRelationship) {
          this.$http.put(`msa-administration/api/BodegaCliente/update-relation/${this.bodegaActual}`, {
            bodega: {
              bodegasId: {
                idEmpresa: this.enterprise.code,
                codigoBodega: this.addRelationship.winery,
              }
            },
            empresa: { idEmpresa: this.enterprise.code },
            codigoCliente: this.addRelationship.client,
            detalleCliente: this.addRelationship.detailClient,
          }).then(res => {
            if (res.status == 200) this.showSuccess(res.data);
            this.getRelacionesHerinco();
            this.modalCloseRelationship();
          }).catch(err => err.response.status == 409 ? this.showError(err.response.data) : console.log(err))

        } else {
          this.$http.post(`msa-administration/api/BodegaCliente/save`, {
            bodega: {
              bodegasId: {
                idEmpresa: this.enterprise.code,
                codigoBodega: this.addRelationship.winery,
              }
            },
            codigoCliente: this.addRelationship.client,
            detalleCliente: this.addRelationship.detailClient
          }).then(res => {
            if (res.status == 200) this.showSuccess(res.data);
            this.getRelacionesHerinco();
            this.modalCloseRelationship();
          }).catch(err => err.response.status == 409 ? this.showError(err.response.data) : console.log(err))
        }
      } else {
        this.showError("Complete todos los campos")
      }

    },

    actualizarEstadoPostgres(relacion, estado) {
      this.$http.put(`msa-administration/api/BodegaCliente/update-state`, {
        bodega: {
          bodegasId: {
            idEmpresa: this.enterprise.code,
            codigoBodega: relacion.COD_BODE,
          }
        },
        empresa: { idEmpresa: this.enterprise.code },
        codigoCliente: relacion.COD_CLIE,
        detalleCliente: relacion.COD_DECL,
        eliminado: estado == "A" ? false : true
      }).then(res => {
        if (res.status == 200) {
          this.showSuccess(res.data)
          this.getRelacionesHerinco();
          this.dialogEstado = false;
          this.showSuccess(res.data)
        } else { this.showError("Error al cambiar de estado") }
      }).catch(err => console.log(err.response.data))
    },


    editRelation(item) {
      this.bodegaActual = '';
      this.bodegaActual = item.COD_BODE;
      this.editRelationship = true;
      this.dialogAgregarRelacion = true;
      this.addRelationship.clientItems = [{ text: item.NOM_CLIE, value: item.COD_CLIE }];
      this.addRelationship.client = item.COD_CLIE;
      this.addRelationship.detailClientItems = [{ text: item.NOM_DECL, value: item.COD_DECL }];
      this.addRelationship.detailClient = item.COD_DECL;
    },



  }
}
</script>


<!-- #################################################################################### -->
<!-- ###### Sección de Styles                                                      ###### -->
<!-- #################################################################################### -->
<style scoped>
.fondoHeder {
  background-color: #1867c0 !important;
  color: white;
}

::v-deep .tablaRelaciones div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaRelaciones div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

th {
  color: black !important;
}

::v-deep .tablaRelaciones div {
  --sb-track-color: #d1cfcf;
  --sb-thumb-color: #8b8585;
  --sb-size: 7px;

  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
}

::v-deep .tablaRelaciones div::-webkit-scrollbar {
  width: var(--sb-size)
}

::v-deep .tablaRelaciones div::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 10px;
}

::v-deep .tablaRelaciones div::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 5px;

}
</style>